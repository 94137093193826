import React, {useState, useEffect, useRef} from "react";
import {
    Box,
    Spinner,
    Text,
    Heading,
    Grid,
    TextInput,
    Button, Card,
} from "grommet";
import { useQuery } from "react-query";
import UserCard from "./UserCard";
import levenshtein from 'js-levenshtein';
import UserTile from "./UserTile";
import { getUsers } from "../../Api/userEditApi";
import { useQueryClient } from 'react-query';

// Define a type for the user data
interface User {
    id: string;
    name: string;
    birthday: string;
    gender: string;
}

function getBestNameDistance(inputName: string, userName: string): number {
    const names = userName.split(" ");
    const reversedNames = [...names].reverse().join(" ");
    let distances = [levenshtein(inputName, userName)];
    if (names.length > 1) {
        distances.push(levenshtein(inputName, reversedNames));
        names.forEach(name => {
            distances.push(levenshtein(inputName, name));
        });
    }
    return Math.min(...distances);
}

function checkValidity(n: number, a: number): [boolean, boolean] {
    if (n <= 0 || a <= 0) {
       return [true, false]
    }

    // Check if a is valid
    const valid = a <= n;

    // If a is not valid, lastNumber should be false
    if (!valid) {
        return [valid, false];
    }

    // Check if a is the last valid number
    let lastNumber = true;
    const aStr = a.toString();
    const nStr = n.toString();

    if (aStr.length === nStr.length) {
        for (let i = 0; i < 10; i++) {
            if (parseInt(aStr + i.toString()) <= n) {
                lastNumber = false;
                break;
            }
        }
    } else {
        lastNumber = a * 10 > n;
    }

    return [valid, lastNumber];
}

//Go from month day year to get a date
function getDate(month: string, day: string, year: string): Date | undefined {

    if (month === "" || day === "" || year === "") {
        return undefined;
    }

    const birthday = new Date(`${month}/${day}/${year}`);
    birthday.setHours(12);
    return birthday;
}

const UserEdit: React.FC = () => {
    const [birthdayInputMM, setBirthdayInputMM] = useState("");
    const [birthdayInputDD, setBirthdayInputDD] = useState("");
    const [birthdayInputYYYY, setBirthdayInputYYYY] = useState("");
    const [nameInput, setNameInput] = useState("");
    const [sortedUsers, setSortedUsers] = useState<User[]>([]);
    const [deleteSelectionFlag, setDeleteSelectionFlag] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [userToDelete, setUserToDelete] = useState<User | null>(null);
    const [showUserSelection, setShowUserSelection] = useState(false);

    const queryClient = useQueryClient();

    const { data, error, isLoading, isFetching } = useQuery<User[], Error>(
        ["users", nameInput, birthdayInputMM, birthdayInputDD, birthdayInputYYYY],
        () => getUsersRequest(),
    );


    function getUsersRequest() {
        return getUsers(getDate(birthdayInputMM, birthdayInputDD, birthdayInputYYYY), nameInput);
    }

    const dayInputRef = useRef<HTMLInputElement | null>(null);
    function setDayFocus() {
        if (dayInputRef.current !== null) {
            dayInputRef.current.focus();
        }
    }

    const yearInputRef = useRef<HTMLInputElement | null>(null);
    function setYearFocus() {
        if (yearInputRef.current !== null) {
            yearInputRef.current.focus();
        }
    }

    function sortUsers(data: User[], nameInput: string, birthdayInput: string): User[] {
        if (data) {
            const sorted = data.slice().sort((a: User, b: User) => {
                let birthdayDistanceA = 0;
                let birthdayDistanceB = 0;

                if (Date.parse(birthdayInput)) { // Check if birthdayInput is a valid date
                    const inputDate = new Date(birthdayInput);
                    const dateA = new Date(a.birthday);
                    const dateB = new Date(b.birthday);
                    birthdayDistanceA = getDistanceInDays(dateA, inputDate);
                    birthdayDistanceB = getDistanceInDays(dateB, inputDate);
                }

                const nameDistanceA = getBestNameDistance(nameInput, a.name);
                const nameDistanceB = getBestNameDistance(nameInput, b.name);

                return (birthdayDistanceA + nameDistanceA) - (birthdayDistanceB + nameDistanceB);
            });
            return sorted;
        }
        return [];
    }

    useEffect(() => {
        if (!isLoading && data) {
            setSortedUsers(
                sortUsers(data, nameInput, `${birthdayInputMM}/${birthdayInputDD}/${birthdayInputYYYY}`)
            );
        } else if (isLoading) {
            setSortedUsers([]);
        }
    }, [data, isLoading, isFetching]);


    function handleUserSelection(user: User) {
        if (deleteSelectionFlag && selectedUser?.id === user.id) {
            return;
        }

        setShowUserSelection(true);
        if (deleteSelectionFlag) {
            setUserToDelete(user);
            setDeleteSelectionFlag(false);
        } else {
            setSelectedUser(user);
        }
    }

    function genUserListComponent(users: User[]) {
        return users.map((user: User) => (
            <UserCard
                key={user.id}
                user={user}
                selectedUser={deleteSelectionFlag ? selectedUser : null}
                onUserClick={(user: User) => handleUserSelection(user)}
            />
        ));
    }

    function getDistanceInDays(date1: Date, date2: Date) {
        return Math.abs(date2.getDate() - date1.getDate());
    }

    function handleUserSelectForMerge() {
        setShowUserSelection(false);
        setDeleteSelectionFlag(true);
    }

    function handleSelectUserTileClose() {
        setSelectedUser(null);
        setUserToDelete(null);
        setShowUserSelection(false);
        setDeleteSelectionFlag(false);
    }

    async function invalidateUserQuery() {
        setSortedUsers([]); // Clear current user list
        await queryClient.invalidateQueries(["users", nameInput, birthdayInputMM, birthdayInputDD, birthdayInputYYYY], {
            refetchActive: true, // Ensure query is refetched immediately
        });
    }


    return (
        <Box pad="small" fill>'

            {(showUserSelection && selectedUser) && (
                <UserTile
                   user={selectedUser}
                   userToDelete={userToDelete}
                   onClose={() => handleSelectUserTileClose()}
                   onSelectUserForMerge={handleUserSelectForMerge}
                />
            )}
            <Box align={"center"} margin={{bottom: "small"}}>
                <Heading>User Edit</Heading>
            </Box>
            <Box direction={"row"} margin={"medium"} style={{flexShrink: 0}} justify={"between"}>
                <Box direction={"row"} gap={"small"} >
                    <Box direction="column" gap="small" width={"20vw"}>
                        <Heading level="4" margin={"none"}>Guest's Name</Heading>
                        <TextInput
                            placeholder="Name"
                            value={nameInput}
                            onChange={(e) => setNameInput(e.target.value)}
                        />
                    </Box>
                    <Box direction="column" gap="small" >
                        <Heading level="4" margin={"none"}>Guest's Birthday</Heading>
                        <Box direction="row" gap="small">
                            <Box width="75px">
                                <TextInput
                                    placeholder="MM"
                                    textAlign={"center"}
                                    value={birthdayInputMM}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const [isValid, isLastNumber] = checkValidity(12, Number(value));
                                        if (isValid) {
                                            setBirthdayInputMM(value);
                                            if (isLastNumber) {
                                                setDayFocus();
                                            }
                                        }
                                    }}
                                    maxLength={2}
                                />
                            </Box>
                            <Text size={"xxlarge"}>-</Text>
                            <Box width="75px">
                                <TextInput
                                    id="dayInput"
                                    ref={dayInputRef}
                                    placeholder="DD"
                                    textAlign={"center"}
                                    value={birthdayInputDD}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const daysInMonth = new Date(Number(birthdayInputYYYY) || 1900, Number(birthdayInputMM), 0).getDate();
                                        const [isValid, isLastNumber] = checkValidity(daysInMonth, Number(value));
                                        if (isValid) {
                                            setBirthdayInputDD(value);
                                            if (isLastNumber) {
                                                setYearFocus();
                                            }
                                        }
                                    }}
                                    maxLength={2}
                                />
                            </Box>
                            <Text size={"xxlarge"}>-</Text>
                            <Box width="100px">
                                <TextInput
                                    id="yearInput"
                                    placeholder="YYYY"
                                    ref={yearInputRef}
                                    textAlign={"center"}
                                    value={birthdayInputYYYY}
                                    onChange={(e) => setBirthdayInputYYYY(e.target.value)}
                                    maxLength={4}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box direction="column" justify={"end"}>
                        <Button
                            margin={{start: "large"}}
                            primary
                            label="Refresh"
                            onClick={() => invalidateUserQuery()}
                        />
                    </Box>
                </Box>
                { deleteSelectionFlag && (
                    <Card fill={"vertical"} background={'red-3'}>
                        <Box justify={"center"} fill margin={"medium"}>
                            <Text>Select the Incorrect User to Delete</Text>
                        </Box>
                    </Card>
                )}
            </Box>
            {isLoading || isFetching ? (
                <Box align="center" justify="center" fill>
                    <Spinner size="large" />
                </Box>
            ) : error ? (
                <Text color="status-critical">An error occurred while fetching users.</Text>
            ) : (
                <Box fill>
                    <Grid
                        pad={"medium"}
                        columns={{ count: "fill", size: 'medium' }}
                        gap="small"
                    >
                        {genUserListComponent(sortedUsers.slice(0,100) || [])}
                    </Grid>
                </Box>
            )}
        </Box>
    );
};

export default UserEdit;
