import {Box, DataTable, Heading, Text, Pagination} from "grommet";
import React, { useState } from 'react';
import CheckinEventHandler from "./CheckinEventHandler";

const DataTableSummary: React.FC = () => {
    const ITEMS_PER_PAGE = 50;
    const [currentPage, setCurrentPage] = useState(1);

    let filteredData = CheckinEventHandler.getInstance().getEvents();

    const columns = [
        { property: 'date', header: 'Date', render: (datum: any) => datum.date.toLocaleDateString() },
        { property: 'guest.UserName', header: 'Name' },
        { property: 'guest.Gender', header: 'Gender' },
        { property: 'guest.Note', header: 'Note' },
        { property: 'category', header: 'Clothing Type' },
        { property: 'familySize', header: 'Family Size', render: (datum: any) => datum['familySize'] ? datum['familySize'] : '' },
        { property: 'homelessStatus', header: 'Homeless Status', render: (datum: any) => datum['homelessStatus'] ? datum['homelessStatus'] : '' },
        { property: 'foodBoxDesignation', header: 'Food Box', render: (datum: any) => datum['foodBoxDesignation'] ? datum['foodBoxDesignation'] : '' },
        { property: 'hygieneItems', header: 'Hygiene Items', render: (datum: any) => datum['hygieneItems'] ? datum['hygieneItems'].join(', ') : '' },
        { property: 'area', header: 'Area', render: (datum: any) => datum['area'] ? datum['area'] : '' },
        { property: 'organization', header: 'Organization', render: (datum: any) => datum['organization'] ? datum['organization'] : '' },
        { property: 'phone', header: 'Phone'},
        { property: 'email', header: 'Email'},
        { property: 'hours', header: 'Hours'},
        { property: 'minutes', header: 'Minutes'},
        { property: 'className', header: <Text>Class Name</Text>, render: (datum: any) => datum['className'] ? datum['className'] : '' },
        { property: 'classLength', header: <Text>Class Length</Text>, render: (datum: any) => datum['classLength'] ? datum['classLength'] : '' },
    ];

    let filteredColumns = columns.filter(column => {
        for(let datum of filteredData) {
            if(column.property === 'guest.UserName'|| column.property === 'guest.Gender' || column.property === 'guest.Note') {
                return true;
            }
            if((datum as any)[column.property]) {
                return true;
            }
        }
        return false;
    });

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const paginatedData = filteredData.slice(startIndex, endIndex);

    return (
        <Box>
            <Heading level={2}>Data Summary</Heading>
            <DataTable columns={filteredColumns} data={paginatedData}/>
            <Pagination
                alignSelf='end'
                margin={{vertical: 'medium'}}
                numberItems={filteredData.length}
                step={ITEMS_PER_PAGE}
                page={currentPage}
                onChange={({ page }) => setCurrentPage(page)}
            />
        </Box>
    );
}

export default DataTableSummary;
